@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  font-weight: 500;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  ::after::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

a {
  text-decoration: none;
  color: #282828;
}

body {
  background: #171a1b;
  color: #282828;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
