@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.button {
  border: none;
  position: absolute;
  top: 40%;
  background-color: transparent;
  z-index: 9999;
  cursor: pointer;
  @include breakpoints(largescreens) {
    right: 7%;
  }
  @include breakpoints(desktop) {
    right: 6%;
  }
  @include breakpoints(laptop) {
    right: 14%;
  }
  @include breakpoints(mobile) {
    top: 14.8rem;
    right: 6%;
  }
  @include breakpoints(tablet) {
    top: 20%;
    right: 1.5rem;
  }
}
