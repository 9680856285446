@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  max-width: 100%;
  max-height: 100%;
  padding: 0.5rem 0 2rem 0;
  @include breakpoints(mobile) {
    margin: 0 0 1rem 0;
  }
}

.list {
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  @include breakpoints(mobile) {
    overflow-x: scroll;
  }

  &__item {
    margin-right: 0.3rem;
  }

  &__item:hover {
    transform: translate(0%, -5%) scale3d(1, 1, 1);
    @include breakpoints(mobile) {
      transform: none;
    }
  }
}

.img {
  border-radius: 25px;
  width: 100%;
  height: 100%;
  @include breakpoints(mobile) {
    width: 280px;
    height: 180px;
  }
}
