@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem 1rem 2rem;
  overflow-y: scroll;
  @include breakpoints(mobile) {
    margin: 2rem 0.8rem;
    height: 100vh;
  }
}
