@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  &__h1 {
    font-size: 60px;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
    color: white;
    @include breakpoints(mobile) {
      font-size: 30px;
      margin: 0 0 0.2rem 0;
    }
  }

  &__h2 {
    font-size: 50px;
    font-weight: 600;
    margin: 0;
    color: white;
    @include breakpoints(mobile) {
      font-size: 20px;
    }
  }
}

.list {
  margin-top: 1rem;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 2rem;
  padding: 0;
  max-width: 100%;
  @include breakpoints(mobile) {
    margin-top: 0.5rem;
    gap: 1rem 0.6rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__p {
      color: white;
      font-weight: 700;
    }
  }
}

.photoWrapper {
  padding-left: 2rem;
  @include breakpoints(mobile) {
    display: none;
  }
}

.img {
  border-radius: 25px;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
  animation: appear 0.3s 0.1s forwards;
}

span {
  color: white;
}
