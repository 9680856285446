@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.popupInner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #777272;
  padding: 2rem 3rem;
  flex-shrink: 1;
  @include breakpoints(mobile) {
    padding: 2rem 1rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonClose {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonUpload {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.h3 {
  margin-top: 1rem;
  color: black;
}

.progressDiv {
  position: relative;
  border: 1px solid black;
  width: 100%;
  height: 1.5rem;
  border-radius: 20px;
  margin: 1rem 0;
}

.fillDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: width 1.4s;
  background: linear-gradient(
    90deg,
    rgba(124, 90, 6, 1) 15%,
    rgba(233, 179, 43, 1) 88%
  );
  color: black;
}

.progress {
  position: absolute;
  top: 0;
  left: 48%;

  // left: 45%;
}
