.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.input {
  border-radius: 10px;
  border: 1px solid black;
  padding: 2% 10%;
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  width: 100%;
}

.label {
  color: black;
}
