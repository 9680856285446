@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoints(mobile) {
  }
}

.img {
  width: 30%;
  height: 100%;
  border-radius: 25px 0 0 25px;
  @include breakpoints(mobile) {
    min-width: 8rem;
    min-height: 5rem;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  @include breakpoints(mobile) {
    align-items: center;
  }
  &__item {
    position: relative;
    background-color: #777272;
    border-radius: 25px 0 0 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    margin-bottom: 2rem;
  }
  &__p {
    color: white;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 3rem;
  }
}

.wrapper {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__p {
    color: black;
    font-weight: 600;
    font-size: 25px;
    @include breakpoints(mobile) {
      font-size: 18px;
    }
  }
  &__a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.imgOtomoto {
  max-width: 40%;
  max-height: 100%;
}

.buttonAdd {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.buttonDelete {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10%;
  right: 2%;
}
