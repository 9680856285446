@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 2rem 3rem 2rem;
  z-index: 9999;
  @include breakpoints(mobile) {
    display: none;
  }
  &__h2 {
    color: white;
    font-size: 16px;
  }
}

.list {
  list-style: none;
  display: flex;
  &__item {
    margin-left: 3rem;
    font-size: 16px;

    &__p {
      color: white;
      margin: 0 0 0.1rem 0;
    }
  }

  &__item:hover {
    transform: translate(0%, -25%);
  }
}

.fillDiv {
  width: 100%;
  height: 1px;
  background-color: white;
}
