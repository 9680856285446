@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.wrapper {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.slider {
  position: absolute;
  display: flex;
  transition: transform 0.2s ease-in-out;
}

.slide {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 1rem;
    border-radius: 25px;
    @include breakpoints(largescreens) {
      max-width: 90%;
      max-height: 100%;
    }
    @include breakpoints(desktop) {
      max-width: 80%;
      max-height: 100%;
    }
    @include breakpoints(laptop) {
      max-width: 60%;
      max-height: 100%;
    }
    @include breakpoints(mobile) {
      max-width: 90%;
      max-height: 100%;
    }
    @include breakpoints(tablet) {
      max-width: 80%;
      max-height: 100%;
    }
  }
}

.link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
}
