@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  @include breakpoints(mobile) {
    justify-content: flex-start;
    margin: 2rem 0.8rem;
    height: 100vh;
  }
}
