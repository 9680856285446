@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 2rem 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  @include breakpoints(mobile) {
    flex-direction: column;
    margin: 2rem 0.8rem;
    height: 100vh;
  }
}
