@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes openTop {
  0% {
    transform: translateY(100%) rotate(0deg);
  }
  100% {
    transform: translateY(0%) rotate(0deg);
  }
}
