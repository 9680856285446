@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.wrapper {
  position: absolute;
  top: 2rem;
  right: 1rem;
  z-index: 9999;
  background-color: rgba(73, 184, 63, 0.8);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 4rem;
  border-radius: 20px;
  opacity: 0;
  animation: appear 1.5s 1s reverse both;

  @include breakpoints(mobile) {
    top: 75%;
    right: 0;
    left: 0;
    padding: 1rem;
  }
}
