@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: appear 0.3s 0.1s forwards;
  margin-left: 2rem;
  @include breakpoints(mobile) {
    display: none;
  }
  &__p {
    font-size: 40px;
    font-weight: 600;
  }
  &__img {
    max-width: 70%;
    max-height: 100%;
  }
}

.img {
  border-radius: 25px;
  max-width: 100%;
  max-height: 100%;
}
