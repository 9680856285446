@use '/src/styles/variables' as *;
@use '/src/styles/mixings' as *;
@use '/src/styles/animations' as *;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: sticky;
  bottom: 2rem;
  background-color: #171a1b;
  margin: 0.2rem 0 0 0;
  z-index: 1;
  @include breakpoints(laptop) {
    display: none;
  }
  @include breakpoints(desktop) {
    display: none;
  }
  @include breakpoints(largescreens) {
    display: none;
  }
}
